import React, { useState } from "react";
import apiPerson from "../../apis/person/apiPerson";
import AppAlert from "../common/AppAlert";

function AssignmentDetailCreateForm({ assignment }) {
	const [inscriptionCode, setInscriptionCode] = useState("");

	const CreateAssignmentDetail = async () => {
		const data = {
			assignment_id: assignment.id,
			inscription_code: inscriptionCode,
		};
		const response = await apiPerson.createAssignmentDetail(data);

		if (response.ok) {
			AppAlert.AlertTopLeft(response.message);
		}
	};

	const OnEnterPress = async (event) => {
		if (event.key === "Enter") {
			CreateAssignmentDetail();
		}
	};
	return (
		<div>
			<label htmlFor="inscription_code">Agregar Detalle:</label>
			<input
				id="inscription_code"
				className="form-control"
				onKeyPress={(event) => OnEnterPress(event)}
				onChange={({ target }) => setInscriptionCode(target.value)}
				placeholder="Codigo de Inscripcion"
			/>
		</div>
	);
}

export default AssignmentDetailCreateForm;
