import axios from "axios";
import apiBase from "../apiBase";

export default {
	createAttendance(data) {
		const url = "api/service/attendance/create";
		return apiBase.post(url, data);
	},

	createService(data) {
		const url = "api/service/create";
		return apiBase.post(url, data);
	},

	updateService(data) {
		const url = "api/service/update";
		return apiBase.put(url, data);
	},

	createExtempAttendance(data) {
		const url = "api/service/attendance/create/extemp";
		return apiBase.post(url, data);
	},

	getCurrentService(datetime = "") {
		const url = "api/service/getCurrentService?time=" + datetime;
		return apiBase.get(url);
	},

	getServicesDropdown() {
		const url = "api/service/getServicesDropdown";
		return apiBase.get(url);
	},

	getAttendances(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/service/attendance/getAttendancesPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},

	getServicesPaginated(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/service/getServicesPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},

	getReportAttendances(page = 1, data) {
		const initDate = data.initDate ? data.initDate : "";
		const endDate = data.endDate ? data.endDate : "";
		const work_unit = data.work_unit ? data.work_unit : "";
		const contractor = data.contractor ? data.contractor : "";
		const service = data.service ? data.service : "";
		const dinner_point = data.dinner_point ? data.dinner_point : "";

		const url =
			"api/service/attendance/report?page=" +
			page +
			"&&initDate=" +
			initDate +
			"&&endDate=" +
			endDate +
			"&&work_unit=" +
			work_unit +
			"&&contractor=" +
			contractor +
			"&&service=" +
			service +
			"&&dinner_point=" +
			dinner_point;
		return apiBase.get(url);
	},

	deleteAttendances(attendance_id) {
		const url = "api/service/attendance/delete/" + attendance_id + "/";
		return apiBase.delete(url);
	},

	deleteService(service_id) {
		const url = "api/service/delete/" + service_id;
		return apiBase.delete(url);
	},

	downloadReport(data, filename) {
		const initDate = data.initDate ? data.initDate : "";
		const endDate = data.endDate ? data.endDate : "";
		const work_unit = data.work_unit ? data.work_unit : "";
		const contractor = data.contractor ? data.contractor : "";
		const service = data.service ? data.service : "";
		const dinner_point = data.dinner_point ? data.dinner_point : "";

		const url =
			"api/service/attendance/report/download?initDate=" +
			initDate +
			"&&endDate=" +
			endDate +
			"&&work_unit=" +
			work_unit +
			"&&contractor=" +
			contractor +
			"&&service=" +
			service +
			"&&dinner_point=" +
			dinner_point;

		return apiBase.getAttachment(url, filename);
	},
};
