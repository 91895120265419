import React, { useEffect, useState } from "react";
import apiService from "../../../apis/service/apiService";
import appColors from "../../../config/appColors";
import helperDate from "../../../helpers/helperDate";
import AppAlert from "../../common/AppAlert";
import BreadCrumb from "../../common/AppBreadCrumb";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";

function ListAttendancesScreen() {
	const headers = ["Fecha", "Usuario", "Persona", "Servicio", "Organización Cliente", "Precio"];
	const values = ["attended_date", "user", "person", "service", "contractor", "price"];
	const buttons = [{ iconName: "trash-alt", iconColor: appColors.red_05, onClick: (item) => showAlertConfirmAttendance(item), style: "btn" }];

	const [attendances, setAttendances] = useState([]);
	const [paginator, setPaginator] = useState({});
	const [filter, setFilter] = useState("");

	const GetAttendances = async (page = 1) => {
		var response = await apiService.getAttendances(page, filter);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setAttendances(response.data.results);
		} else {
			AppAlert.SimpleAlert("Error", "error");
		}
	};

	const showAlertConfirmAttendance = (attendance) => {
		AppAlert.OptionsAlert("Esta asistencia se anulará, y ya no estará disponible para los reportes.", "Anular de todos modos").then((response) => {
			if (response) {
				DeleteAttendance(attendance.id);
			}
		});
	};

	const DeleteAttendance = async (attendance_id) => {
		var response = await apiService.deleteAttendances(attendance_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetAttendances();
		}
	};

	useEffect(() => {
		GetAttendances();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Lista de Asistencias Registradas: " />
				<BreadCrumb items={[{ label: "Asistencias" }, { label: "Lista de Asistencias", active: true }]} />
			</div>
			<div className="col-12">
				<TablePaginate
					headers={headers}
					rows={attendances}
					values={values}
					onSearchApi={(page) => GetAttendances(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
					buttons={buttons}
				/>
			</div>
		</div>
	);
}

export default ListAttendancesScreen;
