import apiBase from "../apiBase";

export default {
	// SUPPLY
	createSupply(supply) {
		const url = "api/sale/supply/create";
		return apiBase.post(url, supply);
	},
	updateSupply(supply) {
		const url = "api/sale/supply/update";
		return apiBase.put(url, supply);
	},
	deleteSupply(supply_id) {
		const url = "api/sale/supply/delete/" + supply_id;
		return apiBase.delete(url);
	},
	getSupplies(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/sale/supply/getSupplies?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getSuppliesPaginated(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/sale/supply/getSuppliesPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getSuppliesDropdown(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/sale/supply/getSuppliessDropdown?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
};
