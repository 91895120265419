import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

function AppCalendar({ label, onChange, value, errors = [] }) {
	return (
		<div>
			<label htmlFor="input">{label}</label>
			<br />
			<DatePicker dateFormat="dd/MM/yyyy" locale="es" selected={value} onChange={onChange} customInput={<input className="form-control" />} />
			{errors.map((error, index) => {
				return (
					<span key={index} className="text-danger small">
						{error}
					</span>
				);
			})}
		</div>
	);
}

export default AppCalendar;
