import apiBase from "../apiBase";

export default {
	getMeasurerStatesDropDown() {
		const url = "api/measurer/getMeasurerStatesDropDown";
		return apiBase.get(url);
	},
	getNewMeasurersPaginated(page = 1, data) {
		const arrival_date = data.arrival_date ? data.arrival_date : "";
		const measurer_state = data.measurer_state ? data.measurer_state : "";

		const url = "api/measurer/getMeasurersPaginated?page=" + page + "&&arrival_date=" + arrival_date + "&&measurer_state=" + measurer_state;
		return apiBase.get(url);
	},
	createNewMeasurer(data) {
		const url = "api/measurer/createNewMeasurer";
		return apiBase.post(url, data);
	},
	downloadReport(data, filename) {
		const arrival_date = data.arrival_date ? data.arrival_date : "";
		const measurer_state = data.measurer_state ? data.measurer_state : "";

		const url = "api/measurer/report/download?arrival_date=" + arrival_date + "&&measurer_state=" + measurer_state;

		return apiBase.getAttachment(url, filename);
	},
};
