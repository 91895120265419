import React, { useEffect, useState } from "react";
import AppAlert from "../common/AppAlert";
import apiDinner from "../../apis/dinner/apiDinner";
import apiRol from "../../apis/rol/apiRol";
import apiUser from "../../apis/user/apiUser";
import AppInput from "../controls/AppInput";
import AppSelect from "../controls/AppSelect";

function UserCreateForm() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [rol, setRol] = useState({});
	const [rols, setRols] = useState([]);
	const [dinnerPoint, setDinnerPoint] = useState({});
	const [dinnerPoints, setDinnerPoints] = useState([]);

	const [errorsForm, setErrorsForm] = useState({});

	const GetDinnerPoints = async () => {
		const response = await apiDinner.getDinnerPoints();
		if (response.ok) {
			var list = [];
			response.data.map((item) => list.push({ label: item.name, value: item.id }));
			setDinnerPoints(list);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const GetRoles = async () => {
		const response = await apiRol.getRols();
		if (response.ok) {
			var list = [];
			response.data.map((item) => list.push({ label: item.name, value: item.id }));
			setRols(list);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const CreateUser = async () => {
		const user = {
			email: email,
			rol: rol.value,
			password: password,
			dinner_point: dinnerPoint.value,
		};

		var response = await apiUser.createUser(user);

		if (response.ok) {
			setErrorsForm({});
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrorsForm(response);
		}
	};

	useEffect(() => {
		GetDinnerPoints();
		GetRoles();

		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="card col-12">
				<div className="card-body row">
					<div className="col-6 form-group">
						<AppInput
							label="Correo Electrónico"
							type="email"
							defaultValue={email}
							onChange={({ target }) => setEmail(target.value)}
							placeholder="Correo Electrónico"
							errors={errorsForm.email}
						/>
					</div>
					<div className="col-6 form-group">
						<AppInput
							label="Contraseña"
							type="password"
							onChange={({ target }) => setPassword(target.value)}
							placeholder="Contraseña"
							errors={errorsForm.password}
						/>
					</div>
					<div className="col-6 form-group">
						<AppSelect
							label="Punto de Servicio"
							items={dinnerPoints}
							onChange={(dinnerPoint) => setDinnerPoint(dinnerPoint)}
							errors={errorsForm.dinner_point}
						/>
					</div>
					<div className="col-6 form-group">
						<AppSelect label="Rol" items={rols} onChange={(rol) => setRol(rol)} errors={errorsForm.rol} />
					</div>
					<div className="text-center m-auto">
						<button className="btn btn-primary my-4" onClick={CreateUser}>
							Registrar Usuario
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserCreateForm;
