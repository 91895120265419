import React, { useEffect, useState } from "react";
import helperDate from "../../helpers/helperDate";

function AppClock() {
	const [dateTimeNow, setDateTimeNow] = useState(new Date());

	useEffect(() => {
		const timerId = setInterval(() => {
			setDateTimeNow(new Date());
		}, 1000);
		return () => {
			clearInterval(timerId);
		};
	}, []);

	return <div>{helperDate.getCurrentString()}</div>;
}

export default AppClock;
