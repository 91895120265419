import React, { useEffect, useState } from "react";
import apiContractor from "../../apis/contractor/apiContractor";
import apiWorkUnit from "../../apis/workUnit/apiWorkUnit";
import apiPerson from "../../apis/person/apiPerson";
import AppAlert from "../common/AppAlert";
import AppInput from "../controls/AppInput";
import AppSelect from "../controls/AppSelect";

function PersonCreateForm() {
	const [names, setNames] = useState("");
	const [lastNames, setLastNames] = useState("");
	const [DNI, setDNI] = useState("");
	const [file, setFile] = useState(null);

	const [contractor, setContractor] = useState({});
	const [contractors, setContractors] = useState([]);
	const [workUnit, setWorkUnit] = useState({});
	const [workUnits, setWorkUnits] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const GetWorkUnits = async () => {
		const response = await apiWorkUnit.getWorkUnits();
		if (response.ok) {
			var list = [];
			response.data.map((item) => list.push({ label: item.name, value: item.id }));
			setWorkUnits(list);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const GetContractors = async () => {
		const response = await apiContractor.getContractors();
		if (response.ok) {
			var list = [];
			response.data.map((item) => list.push({ label: item.name, value: item.id }));
			setContractors(list);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const CreateUser = async () => {
		const person = {
			first_names: names,
			last_names: lastNames,
			dni: DNI,
			contractor: contractor.value,
			work_unit: workUnit.value,
		};

		const response = await apiPerson.createPerson(person);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrorsForm(response);
		}
	};

	const onChangeFile = (file) => {
		setFile(file);
	};

	const uploadfile = async () => {
		const formData = new FormData();
		formData.append("myfile", file, file.name);
		const response = await apiPerson.upload(formData);
	};

	useEffect(() => {
		GetWorkUnits();
		GetContractors();

		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12 card ">
				<div className="card-body">
					<div>
						<div className="row">
							<div className="col-4">
								<AppInput label="Nombres" onChange={({ target }) => setNames(target.value)} placeholder="Nombres" errors={errorsForm.first_names} />
							</div>
							<div className="col-4">
								<AppInput label="Apellidos" onChange={({ target }) => setLastNames(target.value)} placeholder="Apellidos" errors={errorsForm.last_names} />
							</div>
							<div className="col-4">
								<AppInput
									label="Documento de Identidad"
									onChange={({ target }) => setDNI(target.value)}
									placeholder="Documento de identidad"
									errors={errorsForm.dni}
								/>
							</div>
							<div className="col-6 form-group">
								<AppSelect label="Contratista" items={contractors} onChange={(contractor) => setContractor(contractor)} errors={errorsForm.contractor} />
							</div>
							<div className="col-6 form-group">
								<AppSelect label="Unidad de Trabajo" items={workUnits} onChange={(workUnit) => setWorkUnit(workUnit)} errors={errorsForm.work_unit} />
							</div>
						</div>
					</div>
					<div className="text-center my-4">
						<button className="btn btn-primary" onClick={CreateUser}>
							Registrar Persona
						</button>
					</div>
					{/* <div className="hiden">
						<input type="file" onChange={({ target }) => onChangeFile(target.files[0])}></input>
						<button onClick={uploadfile}>Subir</button>
					</div> */}
				</div>
			</div>
		</div>
	);
}

export default PersonCreateForm;
