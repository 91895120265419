import apiBase from "../apiBase";

export default {
	cashdeskReportsByDays(days = 7) {
		const url = "api/home/cashdeskReportsByDays?days=" + days;
		return apiBase.get(url);
	},
	servicesReportsByDays(days = 7) {
		const url = "api/home/servicesReportsByDays?days=" + days;
		return apiBase.get(url);
	},

	getRegistersTables() {
		const url = "api/home/getQuantityRegistersInTables";
		return apiBase.get(url);
	},
};
