import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import appColors from "../../../config/appColors";
import helperDate from "../../../helpers/helperDate";
import AppAlert from "../../common/AppAlert";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppCalendar from "../../controls/AppCalendar";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppSubTitle from "../../controls/AppSubTitle";
import AppTitle from "../../controls/AppTitle";

export default function InstalatePersonScreen() {
	const [instalationDate, setInstalationDate] = useState(new Date());
	const [instalationFormalDate, setInstalationFormalDate] = useState(new Date());
	const [person, setPerson] = useState({});
	const [inCharged, setInCharged] = useState({});
	const [listInChargeds, setListInChargeds] = useState([]);
	const [errors, setErrors] = useState([]);
	const [inscriptionCode, setInscriptionCode] = useState("");
	const [newMeasurer, setNewMeasurer] = useState("");
	const [observations, setObservations] = useState("");
	const [isObserved, setIsObserved] = useState(false);

	const SearchPerson = async () => {
		const response = await apiPerson.getPersonByInscriptionCode(inscriptionCode);

		if (response.ok) {
			setPerson(response.data);
		}
	};

	const ChangeInstalationDate = async (inCharged) => {
		const data = {
			observed: isObserved,
			observations: observations,
			person_id: person ? person.id : null,
			code: newMeasurer,
			in_charged_id: inCharged ? inCharged.value : null,
			instalation_date: helperDate.parseToServerFormat(instalationDate),
			instalation_formal_date: helperDate.parseToServerFormat(instalationFormalDate),
		};
		const response = await apiPerson.changeInstalationDate(data);

		if (response.ok) {
			AppAlert.AlertTopLeft(response.message);

			setPerson({});
			setInscriptionCode("");
			setNewMeasurer("");
			setObservations("");
			setIsObserved(false);
			setInCharged({});
			setErrors([]);
		} else {
			setErrors(response);
		}
	};

	const onChangeInscriptionCode = (event) => {
		if (event.key === "Enter") {
			SearchPerson();
		}
	};

	const GetInChargeds = async () => {
		const response = await apiPerson.getInChargedsDropdown();

		if (response.ok) {
			setListInChargeds(response.data);
		}
	};

	const onSelectChange = async (item) => {
		setInCharged(item);
		ChangeInstalationDate(item);
	};

	useEffect(() => {
		GetInChargeds();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Instalacion de medidor" />
				<AppBreadCrumb items={[{ label: "Personas" }, { label: "Instalacion de medidor", active: true }]} />
			</div>
			<div className="col-md-3 p-3">
				<AppInput
					errors={errors.person_id}
					type="number"
					value={inscriptionCode}
					label="Codigo de Inscripcion"
					onKeyPress={(event) => onChangeInscriptionCode(event)}
					onChange={({ target }) => setInscriptionCode(target.value)}
					placeholder="Codigo Inscripcion"
				/>
			</div>
			<div className="col-md-3 p-3">
				<AppInput
					errors={errors.code}
					value={newMeasurer}
					label="Nuevo Medidor:"
					onChange={({ target }) => setNewMeasurer(target.value)}
					placeholder="Nro Medidor"
				/>
			</div>
			<div className="col-md-6 mt-4 p-3">
				<button className="btn btn-primary w-100" onClick={SearchPerson}>
					BUSCAR
				</button>
			</div>
			<div className="col-md-12">
				<div className="card">
					<div className="card-body row">
						<div className="col-md-6">
							<AppTitle text="Datos del Usuario:" />
							<AppSubTitle color={appColors.red_05} text={"USUARIO: " + person ? person.person : null} />
							<AppSubTitle text={"INSCRIPCION: " + person ? person.inscription_code : null} />
							<AppSubTitle text={"DIRECCION: " + person ? person.address : null} />
						</div>
						{person.id && (
							<div className="col-md-6 row">
								<AppTitle text="Observaciones:" />
								<div className="form-check col-md-12 my-2">
									<input
										className="form-check-input"
										onChange={({ target }) => {
											setObservations("");
											setIsObserved(target.checked);
										}}
										type="checkbox"
										id="flexCheckIndeterminate"
									/>
									<label className="form-check-label" htmlFor="flexCheckIndeterminate">
										Marcar como observado
									</label>
								</div>
								<div className="col-md-12">
									{isObserved && (
										<textarea
											className="form-control"
											onChange={({ target }) => setObservations(target.value)}
											placeholder="Observaciones ..."
											id="observations"
											value={observations}
											style={{ height: 100 }}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-4">
				<AppSelect label="Gasfitero" value={inCharged} errors={errors.in_charged_id} items={listInChargeds} onChange={onSelectChange} placeholder="Gasfitero" />
			</div>
			<div className="col-md-3 p-3">
				<AppCalendar label="Fecha de Instalacion: " errors={errors.instalation_date} onChange={setInstalationDate} value={instalationDate} />
			</div>
			<div className="col-md-3 p-3">
				<AppCalendar
					label="Fecha de Instalacion Formal: "
					errors={errors.instalation_formal_date}
					onChange={setInstalationFormalDate}
					value={instalationFormalDate}
				/>
			</div>
			<div className="col-md-6 mt-4 p-3">
				<button className="btn btn-success w-100" onClick={ChangeInstalationDate}>
					Reportar Instalacion
				</button>
			</div>
		</div>
	);
}
