import React from "react";
import appColors from "../../config/appColors";

function AppSubTitle({ text, color = appColors.black_06 }) {
	return (
		<div>
			<h6 className="mt-4 mb-2" style={{ color: color }}>
				{text}
			</h6>
		</div>
	);
}

export default AppSubTitle;
