import React, { useEffect, useState } from "react";
import apiDinner from "../../../apis/dinner/apiDinner";
import apiSupply from "../../../apis/supply/apiSupply";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";

function SupplyCreateScreen() {
	const [name, setName] = useState("");
	const [price, setPrice] = useState("");
	const [dinner, setDinner] = useState({});
	const [loading, setLoading] = useState(false);
	const [dinnerList, setDinnerList] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const CreateSupply = async () => {
		const supply = {
			name: name,
			dinner_id: dinner.value,
		};

		setLoading(true);
		const response = await apiSupply.createSupply(supply);
		setLoading(false);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
		} else {
			setErrorsForm(response);
		}
	};

	const GetDinners = async () => {
		const response = await apiDinner.getDinnersDropdown();

		if (response.ok) {
			setDinnerList(response.data);
		}
	};

	useEffect(() => {
		GetDinners();
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Insumos:" />
				<BreadCrumb items={[{ label: "Insumos" }, { label: "Registro de Insumo", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-md-6">
						<AppInput
							label="Nombre del Insumo"
							type="text"
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre Insumo"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-6">
						<AppSelect label="Comedor" items={dinnerList} onChange={(dinner) => setDinner(dinner ? dinner : {})} errors={errorsForm.dinner_id} />
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={CreateSupply}>
							Registrar Insumo
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default SupplyCreateScreen;
