const user = "userLogged";

export default {
	getCurrentUser() {
		const userLogged = localStorage.getItem(user);
		if (userLogged) {
			const userJSON = JSON.parse(userLogged);
			return userJSON.user;
		} else {
			return {};
		}
	},
};
