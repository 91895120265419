export default {
  white: '#fff',

  black_00: '#1b2631',
  black_01: '#212f3c',
  black_02: '#34495e',
  black_03: '#283747',
  black_04: '#2e4053',
  black_05: '#34495e',
  black_06: '#5d6d7e',
  black_07: '#85929e',
  black_08: '#aeb6bf',
  black_09: '#d6dbdf',
  black_10: '#ebedef',

  red_00: '#641e16',
  red_01: '#78281f',
  red_02: '#943126',
  red_03: '#b03a2e',
  red_04: '#cb4335',
  red_05: '#e74c3c',
  red_06: '#ec7063',
  red_07: '#f1948a',
  red_08: '#f5b7b1',
  red_09: '#fadbd8',
  red_10: '#fdedec',

  green_00: '#145a32',
  green_01: '#186a3b',
  green_02: '#1d8348',
  green_03: '#239b56',
  green_04: '#28b463',
  green_05: '#2ecc71',
  green_06: '#58d68d',
  green_07: '#82e0aa',
  green_08: '#abebc6',
  green_09: '#d5f5e3',
  green_10: '#eafaf1',

  blue_00: '#154360',
  blue_01: '#1b4f72',
  blue_02: '#21618c',
  blue_03: '#2874a6',
  blue_04: '#2e86c1',
  blue_05: '#3498db',
  blue_06: '#5dade2',
  blue_07: '#85c1e9',
  blue_08: '#aed6f1',
  blue_09: '#d6eaf8',
  blue_10: '#ebf5fb',

  yellow_00: '#7e5109',
  yellow_01: '#7d6608',
  yellow_02: '#9a7d0a',
  yellow_03: '#b7950b',
  yellow_04: '#d4ac0d',
  yellow_05: '#f1c40f',
  yellow_06: '#f4d03f',
  yellow_07: '#f7dc6f',
  yellow_08: '#f9e79f',
  yellow_09: '#fcf3cf',
  yellow_10: '#fef9e7',
};
