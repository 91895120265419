import React, { useEffect, useState } from "react";
import apiCompany from "../../../apis/company/apiCompany";
import apiDinner from "../../../apis/dinner/apiDinner";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import { useHistory, useLocation } from "react-router";

function DinnerCreateForm() {
	const location = useLocation();
	const history = useHistory();

	const [id, setId] = useState(null);
	const [name, setName] = useState("");
	const [company, setCompany] = useState({});
	const [loading, setLoading] = useState(false);
	const [companyList, setCompanyList] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const UpdateDinner = async () => {
		const dinner = {
			id: id,
			name: name,
			company_id: company.value,
		};

		setLoading(true);
		const response = await apiDinner.updateDinner(dinner);
		setLoading(false);

		if (response.ok) {
			history.goBack();
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
		} else {
			setErrorsForm(response);
		}
	};

	const GetCompanies = async (company_id) => {
		const response = await apiCompany.getCompaniesDropdown();

		if (response.ok) {
			const company = response.data.find((x) => x.value == company_id);
			setCompany(company);
			setCompanyList(response.data);
		}
	};

	useEffect(() => {
		const dinner = location.state;
		setId(dinner.id);
		setName(dinner.name);
		GetCompanies(dinner.company);
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Edición de Comedor" />
				<BreadCrumb items={[{ label: "Comedores" }, { label: "Edición de Comedores", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-6">
						<AppInput
							defaultValue={name}
							label="Nombre del Comedor"
							type="text"
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre del Comedor"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-6">
						<AppSelect
							value={company}
							label="Compañía"
							items={companyList}
							onChange={(dinner) => setCompany(dinner ? dinner : {})}
							errors={errorsForm.company_id}
						/>
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={UpdateDinner}>
							Editar Comedor
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default DinnerCreateForm;
