import React, { useEffect, useState } from "react";
import apiHome from "../../../apis/home/apiHome";
import helperDate from "../../../helpers/helperDate";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppTitle from "../../controls/AppTitle";

function UserAttendancesHistory() {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [reports, setReports] = useState([]);
	const [reportsToShow, setReportsToShow] = useState([]);

	let total_attendances_user = 0;

	const GetReportsLastMonth = async () => {
		const response = await apiHome.cashdeskReportsByDays(30);
		if (response.ok) {
			setReports(response.data);
			setReportsToShow(response.data[0]);
		}
	};

	const ChangeSetSelectedUser = (index) => {
		total_attendances_user = 0;
		setSelectedIndex(index);
		setReportsToShow(reports[index]);
	};

	useEffect(() => {
		GetReportsLastMonth();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Historial de asistencias en los últimos 30 días: " />
				<AppBreadCrumb items={[{ label: "Asistencias" }, { label: "Asistencias en el último mes.", active: true }]} />
			</div>
			<div className="col-12 p-5 card">
				<div className="row">
					<div className="col-4">
						<div className="list-group" id="list-tab" role="tablist">
							{reports.map((report, index) => {
								return (
									<a
										key={index}
										className={index == selectedIndex ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}
										id={report[0] && report[0].user}
										data-bs-toggle="list"
										role="tab"
										onClick={() => ChangeSetSelectedUser(index)}
										aria-controls="settings"
									>
										{report[0] && report[0].user}
									</a>
								);
							})}
						</div>
					</div>
					<div className="col-8">
						<div className="tab-content" id="nav-tabContent">
							<div className="table-responsive-md">
								<div className="my-2 p-2 table-warning small">{reportsToShow[selectedIndex] && reportsToShow[selectedIndex].user.toUpperCase()} </div>
								<table className="table text-center small table-md">
									<thead className="table-info">
										<tr className="small">
											<th>Dia</th>
											<th>Inicio</th>
											<th>Fin</th>
											<th>Registros</th>
										</tr>
									</thead>
									<tbody className="table-bordered">
										{reportsToShow.map((report, index) => {
											total_attendances_user += report.total_attendances;
											return (
												<tr key={index} className="small">
													<td>{helperDate.parseToLocalDateFormat(report.attended_day)}</td>
													<td>{helperDate.parseToLocalTimeFormat(report.first_attendance)}</td>
													<td>{helperDate.parseToLocalTimeFormat(report.last_attendance)}</td>
													<td>{report.total_attendances}</td>
													{index + 1 == reportsToShow.length && <td>{"DIAS: " + (index + 1) + " / ASISTENCIAS: " + total_attendances_user}</td>}
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserAttendancesHistory;
