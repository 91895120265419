import React, { useEffect, useState } from "react";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";
import apiSupply from "../../../apis/supply/apiSupply";

function ListSuppliesScreen() {
	const headers = ["Fecha", "Nombre Insumo", "Comedor"];
	const values = ["created", "name", "dinner_name"];
	const buttons = [
		{ onClick: (supply) => EditSelectedSupply(supply), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (supply) => ShowDeleteSelectedSupply(supply), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [supplys, setDinners] = useState([]);
	const [loading, setLoading] = useState(false);

	const GetSupplies = async (page = 1) => {
		setLoading(true);
		var response = await apiSupply.getSuppliesPaginated(page, filter);
		setLoading(false);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setDinners(response.data.results);
		}
	};

	const EditSelectedSupply = (supply) => {
		history.push({ pathname: "/supply/edit", state: supply });
	};

	const ShowDeleteSelectedSupply = (supply) => {
		AppAlert.OptionsAlert(
			"Este insumo se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteSupply(supply.id);
			}
		});
	};

	const DeleteSupply = async (supply_id) => {
		var response = await apiSupply.deleteSupply(supply_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetSupplies();
		}
	};

	useEffect(() => {
		GetSupplies();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Lista de insumos registrados:" />
				<BreadCrumb items={[{ label: "Insumos" }, { label: "Lista de Insumos Registrados", active: true }]} />
			</div>
			<div className="col-12">
				<TablePaginate
					buttons={buttons}
					headers={headers}
					rows={supplys}
					values={values}
					onSearchApi={(page) => GetSupplies(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ListSuppliesScreen;
