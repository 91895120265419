import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/context";
import AppTitle from "../../controls/AppTitle";
import AppSubTitle from "../../controls/AppSubTitle";
import BreadCrumb from "../../common/AppBreadCrumb";
import { LineChart, Line, Tooltip, XAxis, CartesianGrid, ResponsiveContainer, BarChart, Bar, YAxis, Legend } from "recharts";
import apiHome from "../../../apis/home/apiHome";
import helperDate from "../../../helpers/helperDate";
import { useHistory } from "react-router";

function DashboardScreen() {
	const history = useHistory();
	const authContext = useContext(AuthContext);
	const [registersInfo, setRegistersInfo] = useState({});

	const [users, setUsers] = useState([]);
	const [userLogged, setUserLogged] = useState({});
	const [cashdeskList, setCashdeskList] = useState([]);

	const [graphicsByUser, setGraphicsByUser] = useState([]);
	const [graphicsByService, setGraphicsByService] = useState([]);

	const colors = ["#8884d8", "#e25555", "#28bb2e", "#6700ff", "#ff0095", "#ff8c8c", "#ffcb8c"];

	const data = [
		{
			name: "Desayuno",
			user1: 4000,
			user2: 2400,
			user3: 2400,
		},
		{
			name: "Almuerzo",
			user1: 3000,
			user2: 1398,
			user3: 2210,
		},
		{
			name: "Cena",
			user1: 2000,
			user2: 9800,
			user3: 2290,
		},
	];

	const GoAttendancesHistoryByUser = () => {
		history.push("user-attendances");
	};

	const GetCashdeskReport = async () => {
		const response = await apiHome.cashdeskReportsByDays(7);
		if (response.ok) {
			var list = [];
			var users = [];

			response.data.forEach((user) => {
				user.forEach((day) => {
					var foundedDay = list.find((x) => x.name === day.attended_day);

					if (foundedDay) {
						foundedDay[day.user] = day.total_attendances;
					} else {
						var field = {};
						field.name = day.attended_day;
						field[day.user] = day.total_attendances;
						list.push(field);

						users.push(day.user);
					}
				});
			});

			var sortedList = list.sort((a, b) => {
				var date1 = helperDate.parseToLocalDate(a.name);
				var date2 = helperDate.parseToLocalDate(b.name);
				if (date1 > date2) return 1;
				if (date1 < date2) return -1;
				return 0;
			});

			users = users.filter(onlyUnique);

			sortedList.forEach((item) => {
				const date = helperDate.parseToLocalDate(item.name);
				item.name = date.toLocaleDateString();

				users.forEach((user) => {
					if (!item.hasOwnProperty(user)) {
						item[user] = 0;
					}
				});
			});

			setUsers(users);
			setGraphicsByUser(sortedList);
			setCashdeskList(response.data);
		}
	};

	const GetServicesReport = async () => {
		const response = await apiHome.servicesReportsByDays(7);
		if (response.ok) {
			var list = [];
			response.data.map((service) => {
				console.log(service.service);

				service.items.forEach((item) => {
					const itemFound = list.find((x) => x.name == service.service);

					if (itemFound) {
						itemFound[item.user] = item.total_services;
					} else {
						var field = {};
						field[item.user] = item.total_services;
						field.name = service.service;
						list.push(field);
					}
				});
			});

			setGraphicsByService(list);
		}
	};

	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	};

	const GetNumberRegistersTables = async () => {
		const response = await apiHome.getRegistersTables();
		if (response.ok) {
			setRegistersInfo(response.data);
		}
	};

	useEffect(() => {
		GetCashdeskReport();
		GetServicesReport();
		GetNumberRegistersTables();

		if (authContext.userLogged) {
			setUserLogged(authContext.userLogged.user);
		}
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="BIENVENIDO" />
				<BreadCrumb items={[{ label: "Dashboard" }, { label: "Pantalla Principal:", active: true }]} />
			</div>

			<div className="text-center col-12 my-2">
				<img src="../imgs/avatar-man.png" style={{ width: 100, alignSelf: "center" }} className="card-img-top shadow rounded-circle" />
				<AppTitle text={userLogged.email ? userLogged.email.toUpperCase() : null} />
				<AppSubTitle text={userLogged.rol_name ? userLogged.rol_name.toUpperCase() : null} />
			</div>
			<div className="col-md-12 row mt-4">
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-primary shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#fff2f2" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Personas Registrados</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.personsQuantity} personas</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-success shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#f2ffff" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Ventas Realizadas</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.salesQuantity} ventas</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-success shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#f2f7ff" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Asistencias Registradas</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.attendancesQuantity} asistencias </div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-warning shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#fff2f9" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Productos Registrados</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.productsQuantity} unidades</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-warning shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#f9fff2" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Insumos Registrados</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.suppliesQuantity} unidades</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-warning shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#f9fff2" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Contratistas Registradas</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.contractorsQuantity} unidades</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-warning shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#f9fff2" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Comedores Registrados</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.dinnersQuantity} unidades</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6 p-1">
					<div className="card border-left-warning shadow h-60">
						<div className="card-body" style={{ backgroundColor: "#f9fff2" }}>
							<div className="row no-gutters align-items-center">
								<div className="col text-center">
									<div className="small text-uppercase mb-1 label-card">
										<b>Puntos de Servicio Registrados</b>
									</div>
									<div className="h5 mb-0 text-gray-800 small">{registersInfo.dinnersPointsQuantity} unidades</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="text-center py-2">
					<AppSubTitle text="Detalle de las asistencias, en los últimos 7 días." />
					<a className="text-decoration-none small" href="#" onClick={GoAttendancesHistoryByUser}>
						Ver más ...
					</a>
				</div>

				{cashdeskList.map((userItems, index) => {
					return (
						<div key={index} className="table-responsive-md">
							<div className="my-2 p-2 table-warning small">{userItems[0] && userItems[0].user.toUpperCase()}</div>
							<table className="table text-center small table-md">
								<thead className="table-info">
									<tr className="small">
										<th>Dia</th>
										<th>Inicio</th>
										<th>Fin</th>
										<th>Registros</th>
									</tr>
								</thead>
								<tbody className="table-bordered">
									{userItems.map((item, index) => {
										return (
											<tr key={index} className="small">
												<td>{helperDate.parseToLocalDateFormat(item.attended_day)}</td>
												<td>{helperDate.parseToLocalTimeFormat(item.first_attendance)}</td>
												<td>{helperDate.parseToLocalTimeFormat(item.last_attendance)}</td>
												<td>{item.total_attendances}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					);
				})}
			</div>

			<div className="col-md-6">
				<div className="text-center py-2">
					<AppSubTitle text="Gráficos de las asistencias y tipos de consumos frecuentes." />
				</div>
				<div className="col-md-12 p-4 m-4">
					<div className="col-12 pb-4">
						<AppSubTitle text="Asistencias registradas por usuario en los últimos 7 días:" />
					</div>
					<div style={{ width: "100%", height: 300 }}>
						<ResponsiveContainer>
							<LineChart data={graphicsByUser}>
								{users.map((user, index) => {
									return <Line key={index} type="monotone" dataKey={user} stroke={colors[index] ? colors[index] : "#8884d8"} />;
								})}
								<CartesianGrid stroke="#ccc" />
								<XAxis dataKey="name" />
								<YAxis />
								<Legend height={10} />
								<Tooltip />
							</LineChart>
						</ResponsiveContainer>
					</div>
				</div>
				<div className="col-md-12 p-4 m-4">
					<div className="col-12 pb-4">
						<AppSubTitle text="Historial de Servicios atendidos por usuario:" />
					</div>
					<div style={{ width: "100%", height: 300 }}>
						<ResponsiveContainer>
							<BarChart width={500} height={300} data={graphicsByService}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								{users.map((user, index) => {
									return <Bar key={index} type="monotone" dataKey={user} fill={colors[index] ? colors[index] : "#8884d8"} />;
								})}
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DashboardScreen;
