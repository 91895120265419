import React, { useEffect, useState } from "react";
import apiContractor from "../../apis/contractor/apiContractor";
import apiWorkUnit from "../../apis/workUnit/apiWorkUnit";
import apiPerson from "../../apis/person/apiPerson";
import AppAlert from "../common/AppAlert";
import AppInput from "../controls/AppInput";
import AppSelect from "../controls/AppSelect";
import { useHistory, useLocation } from "react-router";

function PersonEditForm() {
	const history = useHistory();
	const location = useLocation();

	const [names, setNames] = useState("");
	const [lastNames, setLastNames] = useState("");
	const [DNI, setDNI] = useState("");
	const [id, setId] = useState(null);

	const [contractor, setContractor] = useState({});
	const [contractors, setContractors] = useState([]);
	const [workUnit, setWorkUnit] = useState({});
	const [workUnits, setWorkUnits] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const GetWorkUnits = async (work_unit_name) => {
		const response = await apiWorkUnit.getWorkUnitsDropdown();
		if (response.ok) {
			const work_unit = response.data.find((x) => x.label == work_unit_name);
			setWorkUnit(work_unit);
			setWorkUnits(response.data);
		}
	};

	const GetContractors = async (contractor_name) => {
		const response = await apiContractor.getContractorsDropdown();
		if (response.ok) {
			const contractor = response.data.find((x) => x.label == contractor_name);
			setContractor(contractor);
			setContractors(response.data);
		}
	};

	const EditPerson = async () => {
		const person = {
			id: id,
			first_names: names,
			last_names: lastNames,
			dni: DNI,
			contractor: contractor.value,
			work_unit: workUnit.value,
		};

		const response = await apiPerson.updatePerson(person);

		if (response.ok) {
			history.goBack();
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrorsForm(response);
		}
	};

	useEffect(() => {
		const person = location.state;

		setId(person.id);
		setDNI(person.dni);
		setNames(person.first_names);
		setLastNames(person.last_names);

		GetWorkUnits(person.work_unit);
		GetContractors(person.contractor);

		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-12 card ">
				<div className="card-body">
					<div>
						<div className="row">
							<div className="col-4">
								<AppInput
									defaultValue={names}
									label="Nombres"
									onChange={({ target }) => setNames(target.value)}
									placeholder="Nombres"
									errors={errorsForm.first_names}
								/>
							</div>
							<div className="col-4">
								<AppInput
									defaultValue={lastNames}
									label="Apellidos"
									onChange={({ target }) => setLastNames(target.value)}
									placeholder="Apellidos"
									errors={errorsForm.last_names}
								/>
							</div>
							<div className="col-4">
								<AppInput
									defaultValue={DNI}
									label="Documento de Identidad"
									onChange={({ target }) => setDNI(target.value)}
									placeholder="Documento de identidad"
									errors={errorsForm.dni}
								/>
							</div>
							<div className="col-6 form-group">
								<AppSelect
									value={contractor}
									label="Contratista"
									items={contractors}
									onChange={(contractor) => setContractor(contractor)}
									errors={errorsForm.contractor}
								/>
							</div>
							<div className="col-6 form-group">
								<AppSelect
									value={workUnit}
									label="Unidad de Trabajo"
									items={workUnits}
									onChange={(workUnit) => setWorkUnit(workUnit)}
									errors={errorsForm.work_unit}
								/>
							</div>
						</div>
					</div>
					<div className="text-center my-4">
						<button className="btn btn-primary" onClick={EditPerson}>
							Editar Persona
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PersonEditForm;
