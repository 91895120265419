import React, { Component } from "react";
import appColors from "../../../config/appColors";

export default class WithdrawalActToPrint extends Component {
	componentDidUpdate() {}
	render() {
		const person = this.props.person;
		const date = this.props.date;

		return (
			<div className="table w-100" style={{ fontSize: 13, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 50, position: "absolute" }}>
				<div className="table w-100" style={{ fontSize: 12, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 0, position: "absolute" }}>
					<span style={{ position: "absolute", left: 500, top: 40 }}>CONSULTAS:</span>
					<span style={{ position: "absolute", left: 650, top: 15 }}>-977 994 875</span>
					<span style={{ position: "absolute", left: 650, top: 30 }}>-920 114 410</span>
					<span style={{ position: "absolute", left: 650, top: 45 }}>-988 113 972</span>
					<span style={{ position: "absolute", left: 650, top: 60 }}>-938 162 233</span>
				</div>
				<span style={{ fontSize: 14, position: "absolute", left: 480, color: appColors.red_05, top: 125 }}>{date}</span>
				<div style={{ fontSize: 13, fontWeight: 600, letterSpacing: 4, fontFamily: "unset", top: 160, position: "relative" }}>
					<span style={{ position: "absolute", left: 20, top: 880 }}>{person.observations}</span>
					<span style={{ position: "absolute", left: 75 }}>EPS SEDACAJ S.A. CAJAMARCA</span>
					<br />
					<br />
					<br />
					<br />

					<span style={{ position: "absolute", left: 120 }}>{person.person}</span>
					<br />
					<span style={{ position: "absolute", left: 120 }}>{person.address}</span>
					<br />
					<span style={{ position: "absolute", left: 150 }}>{person.inscription_code}</span>
					<span style={{ position: "absolute", left: 350 }}>
						{person.contractor_code}-{person.block}-{person.lot}-{person.sub_lot}
					</span>
					<br />
					<br />
					<span style={{ position: "absolute", left: 150 }}>{person.measurer ? person.measurer.code : null}</span>
					<span style={{ position: "absolute", left: 350, top: 800 }}>{person.states_names}</span>
					<span style={{ position: "absolute", left: 350, top: 855 }}>{person.measurer_states}</span>
					<br />
				</div>
			</div>
		);
	}
}
