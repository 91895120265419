import React from "react";
import BreadCrumb from "../../common/AppBreadCrumb";
import PersonEditForm from "../../components/PersonEditForm";
import AppTitle from "../../controls/AppTitle";
function PersonEditScreen() {
	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Edición de Persona: " />
				<BreadCrumb items={[{ label: "Personas" }, { label: "Edición de Personas", active: true }]} />
			</div>
			<div className="col-md-12">
				<PersonEditForm />
			</div>
		</div>
	);
}

export default PersonEditScreen;
