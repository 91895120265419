import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import apiPerson from "../../../apis/person/apiPerson";
import appColors from "../../../config/appColors";
import helperDate from "../../../helpers/helperDate";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import TablePaginate from "../../components/TablePaginate";
import AppCalendarRange from "../../controls/AppCalendarRange";
import AppIcon from "../../controls/AppIcon";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";

function AssignmentScreen() {
	const headers = ["Fecha Asignación", "Asignado", "Nombre", "Direcciones", "Cantidad"];
	const values = ["assignment_date", "assigned", "name", "addresses", "quantity"];
	const buttons = [
		{ onClick: (assignment) => AssginateBlock(assignment), style: "btn", iconName: "hands-helping", iconColor: appColors.blue_05 },
		{ onClick: (assignment) => VerifyBlock(assignment), style: "btn", iconName: "check-double", iconColor: appColors.green_04 },
	];

	const history = useHistory();
	const [isToReport, setIsToReport] = useState(false);
	const [assignmentName, setAssignmentName] = useState("");
	const [assignmentState, setAssignmentState] = useState({});
	const [listEncharged, setListEncharged] = useState([]);
	const [encharged, setEncharged] = useState({});
	const [inscriptionCode, setInscriptionCode] = useState("");
	const [paginator, setPaginator] = useState({});
	const [workUnitList, setWorkUnitList] = useState([]);
	const [initDate, setInitDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const GetAssignments = async (page = 1) => {
		const data = {
			name: assignmentName,
			is_to_report: isToReport,
			encharged_id: encharged ? encharged.value : null,
			assignment_state: assignmentState ? assignmentState.value : null,
			inscription_code: inscriptionCode,
			end_date: helperDate.parseToServerFormat(endDate),
			init_date: helperDate.parseToServerFormat(initDate),
		};
		var response = await apiPerson.getAssignmentsPaginated(page, data);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setWorkUnitList(response.data.results);
		}
	};

	const onChangeDates = async (changeDate) => {
		const [start, end] = changeDate;
		setEndDate(end);
		setInitDate(start);
	};

	const VerifyBlock = (assignment) => {
		history.push({ pathname: "/assignment/verify", state: assignment });
	};

	const AssginateBlock = (assignment) => {
		history.push({ pathname: "/assignment/assignate", state: assignment });
	};

	const OnEnterPress = async (event) => {
		if (event.key === "Enter") {
			GetAssignments();
		}
	};

	const DownloadReport = async () => {
		const data = {
			name: assignmentName,
			is_to_report: isToReport,
			encharged_id: encharged ? encharged.value : null,
			assignment_state: assignmentState ? assignmentState.value : null,
			inscription_code: inscriptionCode,
			end_date: helperDate.parseToServerFormat(endDate),
			init_date: helperDate.parseToServerFormat(initDate),
		};

		apiPerson.DownloadAssignmentsReport(data, "Reporte.xls");
	};

	const GetEnchargeds = async () => {
		const response = await apiPerson.getInChargedsDropdown();
		if (response.ok) {
			setListEncharged(response.data);
		}
	};

	useEffect(() => {
		GetEnchargeds();
		GetAssignments();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-md-12">
				<AppTitle text="Asignaciones Registradas:" />
				<AppBreadCrumb items={[{ label: "Asignaciones" }, { label: "Lista de Asignaciones", active: true }]} />
			</div>
			<div className="col-md-6 text-center row">
				<div className="col-md-12">
					<AppCalendarRange label="Rango del Reporte: " startDate={initDate} endDate={endDate} onChange={onChangeDates} />
				</div>
				<div className="col-md-12 text-center">
					<div className="form-check col-md-12 my-2">
						<hr />
						<input
							className="form-check-input"
							onChange={({ target }) => {
								setIsToReport(target.checked);
							}}
							type="checkbox"
							id="flexCheckIndeterminate"
						/>
						<label className="form-check-label" htmlFor="flexCheckIndeterminate">
							Utilizar Calendario Para reporte
						</label>
						<br />
						<small className="text-success">Solo marcar para utilizar el calendario y el reporte excel.</small>
						<hr />
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="col-md-12">
					<AppSelect label="Gasfitero Asignado:" onChange={setEncharged} items={listEncharged} />
				</div>
				<div className="col-md-12">
					<AppInput
						label="Direccion Asignación:"
						onChange={({ target }) => setAssignmentName(target.value)}
						onKeyPress={(event) => OnEnterPress(event)}
						value={assignmentName}
					/>
				</div>
				<div className="col-md-12">
					<label htmlFor="inscription_code">Codigo Inscripcion</label>
					<input
						id="inscription_code"
						className="form-control"
						onChange={({ target }) => setInscriptionCode(target.value)}
						onKeyPress={(event) => OnEnterPress(event)}
						placeholder="Codigo de Inscripcion"
					/>
				</div>
				{/* <div className="col-md-12">
					<AppSelect
						label="Estado:"
						items={[
							{ label: "Asignados", value: "assigneds" },
							{ label: "Pendientes", value: "pendings" },
						]}
						onChange={setAssignmentState}
					/>
				</div> */}
				{/* <div className="col-md-12">
					<AppSelect
						label="Tipo de Asignación:"
						items={[
							{ label: "Medidores y Accesorios", value: "01" },
							{ label: "Caja Completa", value: "02" },
							{ label: "Marco y Tapa", value: "03" },
							{ label: "Profundas", value: "04" },
							{ label: "Desniveles", value: "05" },
						]}
					/>
				</div> */}
				<div className="col-md-12 text-center p-2">
					<button className="btn btn-sm btn-primary w-50" onClick={() => GetAssignments()}>
						Filtrar
					</button>
					<button className="btn btn-sm btn-link w-50 text-decoration-none" onClick={() => DownloadReport()}>
						<AppIcon iconName="arrow-alt-circle-down" color={appColors.red_04} />
						<span className="text-danger">Descargar Reporte</span>
					</button>
				</div>
			</div>
			<div className="col-md-12">
				<hr />
				<TablePaginate
					searchable={false}
					buttons={buttons}
					headers={headers}
					rows={workUnitList}
					values={values}
					onSearchApi={(page) => GetAssignments(page)}
					paginator={paginator}
				/>
			</div>
		</div>
	);
}

export default AssignmentScreen;
