import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AuthContext from "../src/context/context";

import "./index.css";
import "./sidebar.css";
import HomeScreen from "./apps/HomeScreen";
import WelcomScreen from "./apps/WelcomScreen";

function App() {
	const [userLogged, setUserLogged] = useState(null);

	useEffect(() => {
		const existUser = localStorage.getItem("userLogged");
		if (existUser) {
			setUserLogged(JSON.parse(existUser));
		}
	}, []);

	return (
		<AuthContext.Provider value={{ userLogged, setUserLogged }}>
			<BrowserRouter>{userLogged ? <HomeScreen userLogged={userLogged} /> : <WelcomScreen />}</BrowserRouter>
		</AuthContext.Provider>
	);
}

export default App;
