import React from "react";

function AppTitle({ text }) {
	return (
		<div>
			<h4 className="text-secondary mt-4 mb-2">{text ? text.toUpperCase() : null}</h4>
		</div>
	);
}

export default AppTitle;
