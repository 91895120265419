import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import AppAlert from "../../common/AppAlert";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppSelect from "../../controls/AppSelect";
import AppSubTitle from "../../controls/AppSubTitle";
import AppTitle from "../../controls/AppTitle";
import AppCalendar from "../../controls/AppCalendar";
import helperDate from "../../../helpers/helperDate";
import { useHistory, useLocation } from "react-router";
import appColors from "../../../config/appColors";
import AssignmentDetailCreateForm from "../../components/AssignmentDetailCreateForm";

function AssignmentCreateScreen() {
	const location = useLocation();
	const history = useHistory();
	const [listCodes, setListCodes] = useState([]);
	const [assignment, setAssignment] = useState({});
	const [listEncharged, setListEncharged] = useState([]);
	const [assignationDate, setAssignationDate] = useState(new Date());
	const [encharged, setEncharged] = useState({});
	const [errorsForm, setErrorsForm] = useState({});

	const AssignateAssignment = async () => {
		const assignation = {
			assigned_id: encharged ? encharged.value : null,
			assignment_id: assignment.id,
			assignment_date: helperDate.parseToServerFormat(assignationDate),
		};

		const response = await apiPerson.assignateAssignment(assignation);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
			history.goBack();
		} else {
			setErrorsForm(response);
		}
	};

	const GetInchargeds = async () => {
		const response = await apiPerson.getInChargedsDropdown();
		if (response.ok) {
			setListEncharged(response.data);
		}
	};

	const GetAssignment = async (assignment_id) => {
		const response = await apiPerson.getAssignmentById(assignment_id);

		if (response.ok) {
			setAssignment(response.data);
			setListCodes(response.data.asiggnments);
		}
	};

	useEffect(() => {
		const assignment = location.state;
		GetAssignment(assignment.id);
		GetInchargeds();
	}, []);

	const ShowConfirmDeleteAssignmentDetail = (detail) => {
		AppAlert.OptionsAlert("Se eliminará este detalle de la asignación.", "Eliminar").then((response) => {
			if (response) {
				DeleteAssignmentDetail(detail.id);
			}
		});
	};

	const DeleteAssignmentDetail = async (id) => {
		const response = await apiPerson.deleteAssignmentDetail(id);
		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			const assignment = location.state;
			GetAssignment(assignment.id);
		}
	};

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Asignacion" />
				<AppBreadCrumb items={[{ label: "Asignaciones" }, { label: "Registro de Asignaciones", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-md-12 text-center">
						<AppTitle text={assignment.name} />
					</div>
					<div className="col-md-9 row">
						<div className="col-md-4">
							{assignment.assignment_date ? (
								<AppSubTitle color={appColors.green_04} text={assignment.assigned} />
							) : (
								<AppSelect label="Asignar a:" errors={errorsForm.assigned_id} items={listEncharged} onChange={setEncharged} />
							)}
						</div>
						<div className="col-md-4">
							{assignment.assignment_date ? (
								<AppSubTitle color={appColors.green_04} text={assignment.assignment_date} />
							) : (
								<AssignmentDetailCreateForm assignment={assignment} />
							)}
						</div>
						<div className="col-md-4">
							<AssignmentDetailCreateForm assignment={assignment} />
						</div>
					</div>
					<div className="col-md-3 text-center my-4">
						{!assignment.assignment_date && (
							<button className="btn btn-primary" onClick={AssignateAssignment}>
								Asignar a {encharged && encharged.label}
							</button>
						)}
					</div>
					<div className="col-md-12">
						<AppSubTitle text="DETALLE DE ASIGNACIONES:" />
						<AppSubTitle text={"Total de Asignaciones: " + listCodes.length + " unidades"} />
						<hr />
						<div className="d-flex flex-wrap">
							{listCodes.map((element, index) => {
								return (
									<div key={index} className="col-md-4 my-3">
										<div className="card">
											<div className="col-md-10 card-body">
												<AppSubTitle color={appColors.blue_02} text={element.address} />
												<span className="small">{element.names}</span>
												<br />
												<span className="small">{element.sector_name + " - " + element.block_name}</span>
												<br />
												<span className="small">{element.inscription_code}</span>
											</div>
											<div className="col-md-2 d-flex my-2">
												<button className="btn btn-sm btn-danger" onClick={() => ShowConfirmDeleteAssignmentDetail(element)}>
													Quitar
												</button>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AssignmentCreateScreen;
