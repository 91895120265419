import apiService from "../../apis/service/apiService";
import React, { useEffect, useState } from "react";
import AppAlert from "../common/AppAlert";
import apiPerson from "../../apis/person/apiPerson";
import AppSelect from "../controls/AppSelect";
import helperDate from "../../helpers/helperDate";
import AppClock from "../controls/AppClock";
import AppLoading from "../common/AppLoading";

function AttendanceCreateForm() {
	const [currentService, setCurrentService] = useState({});
	const [personList, setPersonList] = useState([]);
	const [personListToSearch, setPersonListToSearch] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getCurrentService();
		getAllPersons();
		return () => {};
	}, []);

	const getCurrentService = async () => {
		var datetime = helperDate.getCurrentToServer();
		var response = await apiService.getCurrentService(datetime);

		if (response.ok) {
			setCurrentService(response.data);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const getAllPersons = async () => {
		var response = await apiPerson.getPersonsDropdown();
		if (response.ok) {
			setPersonList(response.data);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const createAttendance = async (person_id) => {
		const attendance = {
			total_paid: currentService.price,
			attended_date: helperDate.getCurrentToServer(),
			service_id: currentService.id,
			person_id: person_id,
		};

		setLoading(true);
		const response = await apiService.createAttendance(attendance);
		setLoading(false);

		if (response.ok) {
			getCurrentService();
			AppAlert.SimpleAlert(response.message);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const onInputChange = (value = "") => {
		if (value && value.length > 2) {
			var persons = personList;
			const filteredPersons = persons.filter((x) => x.label.toUpperCase().includes(value.toUpperCase()));
			setPersonListToSearch(filteredPersons);
		} else {
			setPersonListToSearch([]);
		}
	};

	const onSelectChange = async (item) => {
		if (item) await createAttendance(item.value);
	};

	return (
		<div className="row">
			<div className="col-12 card shadow">
				<div className="row card-body">
					<div className="col-12 form-group text-center">
						<label htmlFor="exampleInputtext1">
							<b>HORA Y FECHA DE ATENCIÓN</b>
						</label>
						<AppClock />
					</div>
					<div className="col-6 form-group">
						<AppSelect label="Datos del cliente" onInputChange={onInputChange} onChange={(item) => onSelectChange(item)} items={personListToSearch} />
					</div>
					<div className="col-6 form-group">
						<label htmlFor="exampleInputtext1">Tipo de servicio</label>
						<input className="form-control" defaultValue={currentService.name} readOnly placeholder="Servicio no disponible para esta hora del dia" />
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default AttendanceCreateForm;
