import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import AppAlert from "../../common/AppAlert";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppSubTitle from "../../controls/AppSubTitle";
import helperDate from "../../../helpers/helperDate";
import AppTitle from "../../controls/AppTitle";
import AssignationList from "../../components/AssignationList";
import AppCalendar from "../../controls/AppCalendar";

function AssignmentCreateScreen() {
	const [name, setName] = useState("");
	const [assignmentType, setAssignmentType] = useState({});
	const [listEncharged, setListEncharged] = useState([]);
	const [inscriptionCode, setInscriptionCode] = useState("");
	const [listCodes, setListCodes] = useState([]);
	const [encharged, setEncharged] = useState({});
	const [assignmentDate, setAssignmentDate] = useState(null);
	const [errorsForm, setErrorsForm] = useState({});

	const CreateAssignment = async () => {
		const assignation = {
			name: name,
			assignments: listCodes,
			assignment_date: assignmentDate ? helperDate.parseToServerFormat(assignmentDate) : "",
			assigned_id: encharged ? encharged.value : undefined,
			assignment_type: assignmentType ? assignmentType.value : null,
		};

		const response = await apiPerson.createAssignment(assignation);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
			setListCodes([]);
			setInscriptionCode("");
		} else {
			setErrorsForm(response);
		}
	};

	const GetEnchargeds = async () => {
		const response = await apiPerson.getInChargedsDropdown();
		if (response.ok) {
			setListEncharged(response.data);
		}
	};

	const SearchPersonByInscriptionCode = async () => {
		const response = await apiPerson.getPersonByInscriptionCode(inscriptionCode);
		if (response.ok) {
			var codes = listCodes;
			const exist = listCodes.find((x) => x.inscription_code == response.data.inscription_code);

			if (!exist) {
				AppAlert.AlertTopLeft(response.message);
				codes.push({
					installed: false,
					block: response.data.block,
					person_id: response.data.id,
					person: response.data.person,
					address: response.data.address,
					inscription_code: response.data.inscription_code,
					assignment_id: encharged ? encharged.value : null,
				});
				setListCodes(codes);
				setInscriptionCode("");
				setErrorsForm({});
			}
		}
	};

	const OnEnterPress = async (event) => {
		if (event.key === "Enter") {
			SearchPersonByInscriptionCode();
		}
	};

	const removeElement = (index) => {
		var codes = listCodes;
		codes.splice(index, 1);
		setErrorsForm({});
		setListCodes(codes);
		setInscriptionCode("");
	};

	useEffect(() => {
		GetEnchargeds();
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Asignacion" />
				<AppBreadCrumb items={[{ label: "Asignaciones" }, { label: "Registro de Asignaciones", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-md-3">
						<AppInput
							label="Nombre de la Asignacion"
							type="text"
							onChange={({ target }) => setName(target.value)}
							placeholder="Asignacion"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-md-3">
						<AppSelect
							label="Tipo de Asignación:"
							errors={errorsForm.assignment_type}
							items={[
								{ label: "Medidores y Accesorios", value: "01" },
								{ label: "Caja Completa", value: "02" },
								{ label: "Marco y Tapa", value: "03" },
								{ label: "Profundas", value: "04" },
								{ label: "Desniveles", value: "05" },
							]}
							onChange={setAssignmentType}
						/>
					</div>
					<div className="col-md-3">
						<AppSelect label="Asignado:" errors={errorsForm.assigned_id} items={listEncharged} onChange={setEncharged} />
					</div>
					<div className="col-md-3">
						<AppCalendar value={assignmentDate} label="Fecha de Asignacion:" errors={errorsForm.assigned_date} onChange={setAssignmentDate} />
					</div>
					<div className="col-md-12">
						<AppSubTitle text="Agregar códigos: " />
					</div>
					<div className="col-md-4">
						<AppInput
							label="Codigo de Inscripción:"
							onChange={({ target }) => setInscriptionCode(target.value)}
							onKeyPress={(event) => OnEnterPress(event)}
							value={inscriptionCode}
							type="number"
						/>
					</div>
					<div className="col-md-6">
						<AppSubTitle text="Codigos Asignados:" />
						<AppSubTitle text={"Total de Asignaciones: " + listCodes.length + " unidades"} />
						<AssignationList items={listCodes} onButtonClick={(index) => removeElement(index)} />
					</div>
					<div className="col-md-2 text-center my-4">
						<button className="btn btn-primary" onClick={CreateAssignment}>
							Registrar Asignacion
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AssignmentCreateScreen;
