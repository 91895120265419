import apiBase from "../apiBase";

export default {
	createWorkUnit(workUnit) {
		const url = "api/company/workUnit/create";
		return apiBase.post(url, workUnit);
	},
	updateWorkUnit(workUnit) {
		const url = "api/company/workUnit/update";
		return apiBase.put(url, workUnit);
	},
	deleteWorkUnit(workUnit_id) {
		const url = "api/company/workUnit/delete/" + workUnit_id;
		return apiBase.delete(url);
	},
	getWorkUnits() {
		const url = "api/company/workUnit/getAllWorkUnits";
		return apiBase.get(url);
	},
	getWorkUnitsPaginated(page = 1, names = "") {
		const url = "api/company/workUnit/getWorkUnitsPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getWorkUnitsDropdown() {
		const url = "api/company/workUnit/getWorkUnitsDropdown";
		return apiBase.get(url);
	},
};
