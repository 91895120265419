import apiBase from "../apiBase";

export default {
	getCompaniesDropdown(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/company/getCompaniesDropdown?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getCompanies(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/company/getCompanies?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
};
