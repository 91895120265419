import React from "react";

function AppInput({ onChange, value, label, placeholder, errors = [], type = "text", defaultValue, onKeyPress }) {
	return (
		<div className="form-group">
			<label className="" htmlFor="input">
				{label}
			</label>
			<input
				id="input"
				type={type}
				onChange={onChange}
				value={value}
				onKeyPress={onKeyPress}
				defaultValue={defaultValue}
				className="form-control"
				placeholder={placeholder}
			/>
			{errors.map((error, index) => {
				return (
					<span key={index} className="text-danger small">
						{error}
					</span>
				);
			})}
		</div>
	);
}

export default AppInput;
