import React, { useEffect, useState } from "react";
import apiDinner from "../../../apis/dinner/apiDinner";
import apiProduct from "../../../apis/product/apiProduct";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import { useHistory, useLocation } from "react-router";

function ProductEditScreen() {
	const history = useHistory();
	const location = useLocation();

	const [id, setId] = useState(null);
	const [name, setName] = useState("");
	const [price, setPrice] = useState("");
	const [dinner, setDinner] = useState({});
	const [loading, setLoading] = useState(false);
	const [dinnerList, setDinnerList] = useState([]);
	const [errorsForm, setErrorsForm] = useState({});

	const CreateProduct = async () => {
		const product = {
			id: id,
			name: name,
			price: price,
			dinner_id: dinner.value,
		};

		setLoading(true);
		const response = await apiProduct.updateProduct(product);
		setLoading(false);

		if (response.ok) {
			history.goBack();
			AppAlert.SimpleAlert(response.message);
			setErrorsForm({});
		} else {
			setErrorsForm(response);
		}
	};

	const GetDinners = async (dinner_id) => {
		const response = await apiDinner.getDinnersDropdown();

		if (response.ok) {
			const dinner = response.data.find((x) => x.value == dinner_id);
			setDinner(dinner);
			setDinnerList(response.data);
		}
	};

	useEffect(() => {
		const product = location.state;
		setId(product.id);
		setName(product.name);
		setPrice(product.price);

		GetDinners(product.dinner);
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Edición de Producto:" />
				<BreadCrumb items={[{ label: "Productos" }, { label: "Edición de Producto", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-md-3">
						<AppInput
							label="Nombre del Producto"
							defaultValue={name}
							type="text"
							onChange={({ target }) => setName(target.value)}
							placeholder="Nombre Producto"
							errors={errorsForm.name}
						/>
					</div>
					<div className="col-md-3">
						<AppInput
							label="Precio del Producto"
							defaultValue={price}
							type="number"
							onChange={({ target }) => setPrice(target.value)}
							placeholder="Precio Producto"
							errors={errorsForm.price}
						/>
					</div>
					<div className="col-6">
						<AppSelect label="Comedor" value={dinner} items={dinnerList} onChange={(dinner) => setDinner(dinner ? dinner : {})} errors={errorsForm.dinner_id} />
					</div>
					<div className="col-12 text-center my-4">
						<button className="btn btn-primary" onClick={CreateProduct}>
							Editar Producto
						</button>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ProductEditScreen;
