import React from "react";
import { Route, Switch } from "react-router";
import Header from "./common/AppHeader";
import AttendanceExtempScreen from "./screens/attendance/AttendanceExtempScreen";
import AttendanceScreen from "./screens/attendance/AttendanceScreen";
import ListAttendancesScreen from "./screens/attendance/ListAttendancesScreen";
import ListContractorsScreen from "./screens/contractor/ListContractorsScreen";
import DinnerCreateForm from "./screens/dinner/DinnerCreateScreen";
import DinnerEditScreen from "./screens/dinner/DinnerEditScreen";
import ListDinnersScreen from "./screens/dinner/ListDinnersScreen";
import DinnerPointCreateForm from "./screens/dinnerPoint/DinnerPointCreateScreen";
import ListDinnerPointsScreen from "./screens/dinnerPoint/ListDinnerPointsScreen";
import FormsScreen from "./screens/forms/FormsScreen";
import MeterInstallationCertificate from "./screens/forms/MeterInstallationCertificate";
import MeterWithdrawalAct from "./screens/forms/MeterWithdrawalAct";
import MeterWithdrawalNotification from "./screens/forms/MeterWithdrawalNotification";
import ListPersonsScreen from "./screens/person/ListPersonsScreen";
import PersonScreen from "./screens/person/PersonCreateScreen";
import AttendanceReportScreen from "./screens/reports/AttendanceReportScreen";
import ListServicesScreen from "./screens/service/ListServicesScreen";
import ListUsersScreen from "./screens/user/ListUsersScreen";
import UserCreateScreen from "./screens/user/UserCreateScreen";
import ListWorkUnitsScreen from "./screens/workUnit/ListWorkUnitsScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import UserEditScreen from "./screens/user/UserEditScreen";
import Footer from "./common/AppFooter";
import PersonEditScreen from "./screens/person/PersonEditForm";
import DinnerPointEditForm from "./screens/dinnerPoint/DinnerPointEditScreen";
import ContractorEditScreen from "./screens/contractor/ContractorEditScreen";
import ContractorCreateScreen from "./screens/contractor/ContractorCreateScreen";
import ServiceEditScreen from "./screens/service/ServiceEditScreen";
import ServiceCreateScreen from "./screens/service/ServiceCreateScreen";
import WorkUnitCreateScreen from "./screens/workUnit/WorkUnitCreateScreen";
import WorkUnitEditScreen from "./screens/workUnit/WorkUnitEditScreen";
import SaleCreateScreen from "./screens/sale/SaleCreateScreen";
import ListSaleScreen from "./screens/sale/ListSaleScreen";
import ProductCreateScreen from "./screens/product/ProductCreateScreen";
import ProductEditScreen from "./screens/product/ProductEditScreen.js";
import ListProductsScreen from "./screens/product/ListProductsScreen";
import SupplyCreateScreen from "./screens/supply/SupplyCreateScreen";
import SupplyEditScreen from "./screens/supply/SupplyEditScreen";
import ListSuppliesScreen from "./screens/supply/ListSuppliesScreen";
import SaleEditScreen from "./screens/sale/SaleEditScreen";
import SaleDetailScreen from "./screens/sale/SaleDetailScreen";
import UserAttendancesHistory from "./screens/dashboard/UserAttendancesHistory";
import NotificatePersonScreen from "./screens/notifications/NotificatePersonScreen";
import ListPersonsNotifications from "./screens/notifications/ListPersonsNotificationScreen";
import ListPersonsInstalationScreen from "./screens/instalations/ListPersonsInstalationScreen";
import InstalatePersonScreen from "./screens/instalations/InstalatePersonScreen";
import SedacajReportsScreen from "./screens/reports/SedacajReportsScreen";
import AssignmentScreen from "./screens/assignment/AssignmentScreen";
import AssignmentCreateScreen from "./screens/assignment/AssignmentCreateScreen";
import AssignateScreen from "./screens/assignment/AssignateScreen";
import AssignationsVerifyScreen from "./screens/assignment/AssignationsVerifyScreen";
import ObservationPersonScreen from "./screens/person/ObservationPersonScreen";
import MeasurerCreateScreen from "./screens/measurer/MeasurerCreateScreen";
import ReportMeasurerScreen from "./screens/measurer/ReportMeasurerScreen";

function HomeScreen({ userLogged = {} }) {
	const { user } = userLogged;

	const menu_customer_01_and_rol_01 = [
		{
			title: "Asistencias",
			icon: "business-time",
			submenu: [
				{ icon: "business-time", label: "Registro de Asistencias", route: "/attendance/create" },
				{ icon: "calendar-plus", label: "Registro de asistencias Extemporáneas", route: "/attendance/extemp" },
				{ icon: "list-ul", label: "Lista de Asistencias", route: "/attendance" },
			],
		},
		{
			title: "Reportes",
			icon: "file-invoice",
			submenu: [{ icon: "file-invoice", label: "Reporte de Asistencias", route: "/attendance-report" }],
		},
		{
			title: "Personas",
			icon: "male",
			submenu: [
				{ icon: "male", label: "Registro de Personas", route: "/person" },
				{ icon: "list-ul", label: "Lista de Personas", route: "/person/list" },
			],
		},
		{
			title: "Usuarios",
			icon: "user",
			submenu: [
				{ icon: "user", label: "Registro de Usuarios", route: "/user" },
				{ icon: "list-ul", label: "Lista de Usuarios", route: "/user/list" },
			],
		},
		{
			title: "Comedores",
			icon: "utensils",
			submenu: [
				{ icon: "utensils", label: "Comedores", route: "/dinner" },
				{ icon: "map-marked", label: "Puntos de Servicio", route: "/dinner-point" },
			],
		},
		{
			title: "Ventas",
			icon: "cash-register",
			submenu: [
				{ icon: "cash-register", label: "Registro De Venta", route: "/sale" },
				{ icon: "list-ul", label: "Lista de Ventas", route: "/sale/list" },
			],
		},
		{
			title: "Productos",
			icon: "drumstick-bite",
			submenu: [
				{ icon: "drumstick-bite", label: "Registro Productos", route: "/product" },
				{ icon: "list-ul", label: "Lista de Productos", route: "/product/list" },
			],
		},
		{
			title: "Insumos",
			icon: "carrot",
			submenu: [
				{ icon: "carrot", label: "Registro Insumos", route: "/supply" },
				{ icon: "list-ul", label: "Lista de insumos", route: "/supply/list" },
			],
		},
		{
			title: "Extras",
			icon: "concierge-bell",
			submenu: [
				{ icon: "address-card", label: "Contratistas", route: "/contractor" },
				{ icon: "concierge-bell", label: "Servicios", route: "/service" },
				{ icon: "briefcase", label: "Unidades de Trabajo", route: "/work-unit" },
			],
		},
	];

	const menu_customer_01_and_rol_02 = [
		{
			title: "Asistencias",
			icon: "business-time",
			submenu: [
				{ icon: "business-time", label: "Registro de Asistencias", route: "/attendance/create" },
				{ icon: "calendar-plus", label: "Registro de asistencias Extemporáneas", route: "/attendance/extemp" },
				{ icon: "list-ul", label: "Lista de Asistencias", route: "/attendance" },
			],
		},
		{
			title: "Reportes",
			icon: "file-invoice",
			submenu: [{ icon: "file-invoice", label: "Reporte de Asistencias", route: "/attendance-report" }],
		},
		{
			title: "Personas",
			icon: "male",
			submenu: [
				{ icon: "male", label: "Registro de Personas", route: "/person" },
				{ icon: "list-ul", label: "Lista de Personas", route: "/person/list" },
			],
		},
		{
			title: "Ventas",
			icon: "cash-register",
			submenu: [
				{ icon: "cash-register", label: "Registro De Venta", route: "/sale" },
				{ icon: "list-ul", label: "Lista de Ventas", route: "/sale/list" },
			],
		},
		{
			title: "Productos",
			icon: "drumstick-bite",
			submenu: [
				{ icon: "drumstick-bite", label: "Registro Productos", route: "/product" },
				{ icon: "list-ul", label: "Lista de Productos", route: "/product/list" },
			],
		},
		{
			title: "Insumos",
			icon: "carrot",
			submenu: [
				{ icon: "carrot", label: "Registro Insumos", route: "/supply" },
				{ icon: "list-ul", label: "Lista de insumos", route: "/supply/list" },
			],
		},
	];

	const GetCustomerMenu = () => {
		switch (user.customer_code) {
			case "01":
				if (user.rol_code == "01") return menu_customer_01_and_rol_01;
				else if (user.rol_code == "02") return menu_customer_01_and_rol_02;
				break;
			default:
				return [];
		}
		return menu_customer_01_and_rol_01;
	};

	return (
		<div className="container-fluid">
			<Header menu={GetCustomerMenu()} user={user} />
			<div className="container mb-5">
				<Switch>
					<Route path="/attendance/create">
						<AttendanceScreen />
					</Route>
					<Route path="/attendance/extemp">
						<AttendanceExtempScreen />
					</Route>
					<Route path="/attendance">
						<ListAttendancesScreen />
					</Route>
					<Route path="/user/list">
						<ListUsersScreen />
					</Route>
					<Route path="/user/edit">
						<UserEditScreen />
					</Route>
					<Route path="/user">
						<UserCreateScreen />
					</Route>
					<Route path="/person/list">
						<ListPersonsScreen />
					</Route>
					<Route path="/person/edit">
						<PersonEditScreen />
					</Route>
					<Route path="/person/notification/list">
						<ListPersonsNotifications />
					</Route>
					<Route path="/person/notification">
						<NotificatePersonScreen />
					</Route>
					<Route path="/person/observation">
						<ObservationPersonScreen />
					</Route>
					<Route path="/person/instalation/list">
						<ListPersonsInstalationScreen />
					</Route>
					<Route path="/person/instalation">
						<InstalatePersonScreen />
					</Route>
					<Route path="/person">
						<PersonScreen />
					</Route>
					<Route path="/dinner/create">
						<DinnerCreateForm />
					</Route>
					<Route path="/dinner/edit">
						<DinnerEditScreen />
					</Route>
					<Route path="/dinner">
						<ListDinnersScreen />
					</Route>
					<Route path="/dinner-point/create">
						<DinnerPointCreateForm />
					</Route>
					<Route path="/dinner-point/edit">
						<DinnerPointEditForm />
					</Route>
					<Route path="/dinner-point">
						<ListDinnerPointsScreen />
					</Route>
					<Route path="/print-forms/installation-certificate">
						<MeterInstallationCertificate />
					</Route>
					<Route path="/print-forms/withdrawal-notification">
						<MeterWithdrawalNotification />
					</Route>
					<Route path="/print-forms/withdrawal-act">
						<MeterWithdrawalAct />
					</Route>
					<Route path="/print-forms">
						<FormsScreen />
					</Route>
					<Route path="/attendance-report">
						<AttendanceReportScreen />
					</Route>
					<Route path="/sedacaj-report">
						<SedacajReportsScreen />
					</Route>
					<Route path="/assignment/list">
						<AssignmentScreen />
					</Route>
					<Route path="/assignment/create">
						<AssignmentCreateScreen />
					</Route>
					<Route path="/assignment/assignate">
						<AssignateScreen />
					</Route>
					<Route path="/assignment/verify">
						<AssignationsVerifyScreen />
					</Route>
					<Route path="/service/create">
						<ServiceCreateScreen />
					</Route>
					<Route path="/service/edit">
						<ServiceEditScreen />
					</Route>
					<Route path="/service">
						<ListServicesScreen />
					</Route>
					<Route path="/work-unit/create">
						<WorkUnitCreateScreen />
					</Route>
					<Route path="/work-unit/edit">
						<WorkUnitEditScreen />
					</Route>
					<Route path="/work-unit">
						<ListWorkUnitsScreen />
					</Route>
					<Route path="/contractor/edit">
						<ContractorEditScreen />
					</Route>
					<Route path="/contractor/create">
						<ContractorCreateScreen />
					</Route>
					<Route path="/contractor">
						<ListContractorsScreen />
					</Route>
					<Route path="/supply/list">
						<ListSuppliesScreen />
					</Route>
					<Route path="/supply/edit">
						<SupplyEditScreen />
					</Route>
					<Route path="/supply">
						<SupplyCreateScreen />
					</Route>
					<Route path="/product/list">
						<ListProductsScreen />
					</Route>
					<Route path="/product/edit">
						<ProductEditScreen />
					</Route>
					<Route path="/product">
						<ProductCreateScreen />
					</Route>
					<Route path="/sale/list">
						<ListSaleScreen />
					</Route>
					<Route path="/sale/edit">
						<SaleEditScreen />
					</Route>
					<Route path="/sale/detail">
						<SaleDetailScreen />
					</Route>
					<Route path="/sale">
						<SaleCreateScreen />
					</Route>
					<Route path="/user-attendances">
						<UserAttendancesHistory />
					</Route>
					<Route path="/measurer/register">
						<MeasurerCreateScreen />
					</Route>
					<Route path="/measurer">
						<ReportMeasurerScreen />
					</Route>
					<Route path="/">
						<DashboardScreen />
					</Route>
				</Switch>
			</div>
			<Footer />
		</div>
	);
}

export default HomeScreen;
