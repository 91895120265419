import React from "react";
import BreadCrumb from "../../common/AppBreadCrumb";
import UserEditForm from "../../components/UserEditForm";
import AppTitle from "../../controls/AppTitle";

function UserEditScreen() {
	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Edición de Usuario:" />
				<BreadCrumb items={[{ label: "Usuarios" }, { label: "Registro de Usuarios:", active: true }]} />
			</div>
			<div className="col-12">
				<UserEditForm />
			</div>
		</div>
	);
}

export default UserEditScreen;
