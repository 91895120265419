import React, { useEffect, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import AppAlert from "../../common/AppAlert";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppSubTitle from "../../controls/AppSubTitle";
import AppTitle from "../../controls/AppTitle";
import { useLocation } from "react-router";
import appColors from "../../../config/appColors";

function AssignationsVerifyScreen() {
	const location = useLocation();
	const [listCodes, setListCodes] = useState([]);
	const [assignment, setAssignment] = useState({});
	const [detail, setDetail] = useState("");
	const [listEncharged, setListEncharged] = useState([]);

	const GetInchargeds = async () => {
		const response = await apiPerson.getInChargedsDropdown();
		if (response.ok) {
			setListEncharged(response.data);
		}
	};

	const GetAssignment = async (assignment_id) => {
		const response = await apiPerson.getAssignmentById(assignment_id);

		if (response.ok) {
			setAssignment(response.data);
			setListCodes(response.data.asiggnments);
			let instalations = 0;
			response.data.asiggnments.forEach((element) => {
				if (element.instalation_date) instalations++;
			});
			setDetail("Existen " + instalations + " instalaciones realizadas en esta asignación, de un total de " + response.data.asiggnments.length);
		}
	};

	const InstalateDetail = async (detail, checked) => {
		const assignmentDetail = {
			id: detail.id,
			installed: checked,
		};
		const response = await apiPerson.instalateAssignmentDetail(assignmentDetail);
		if (response.ok) {
			AppAlert.AlertTopLeft(response.message);
		}
	};

	useEffect(() => {
		const assignment = location.state;
		GetAssignment(assignment.id);
		GetInchargeds();
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<AppTitle text="Registro de Asignacion" />
				<AppBreadCrumb items={[{ label: "Asignaciones" }, { label: "Registro de Asignaciones", active: true }]} />
			</div>
			<div className="card col-12">
				<div className="row card-body col-12">
					<div className="col-md-12 text-center">
						<AppTitle text={assignment.name} />
					</div>
					<div className="col-md-12 text-center">
						<AppSubTitle color={appColors.green_05} text={assignment.assigned} />
						<AppSubTitle color={appColors.blue_05} text={detail} />
					</div>
					<div className="col-md-12">
						<AppSubTitle text="DETALLE DE ASIGNACIONES:" />
						{listCodes.map((element, index) => {
							return (
								<div key={index} className="col-md-4 my-3">
									<div className="card">
										<div className="col-md-12 card-body row">
											<div className="col-md-8">
												<AppSubTitle color={appColors.blue_02} text={element.names} />
												<span className="text-danger">{element.inscription_code}</span>
												<br />
												<span className="small">{element.address}</span>
												<br />
												<span className="small">{element.sector_name + " - " + element.block_name}</span>
												<br />
												<div className="col-md-2 form-check">
													<input
														type="checkbox"
														id={"installed_" + index}
														className="form-check-input"
														value={element.installed}
														defaultChecked={element.installed}
														onChange={({ target }) => InstalateDetail(element, target.checked)}
													/>
													<label className="form-check-label">Instalado:</label>
												</div>
											</div>
											<div className="col-md-4">
												<AppSubTitle color={appColors.blue_02} text="Instalado:" />
												<span className="text-danger">{element.instalation_date}</span>
											</div>
										</div>
									</div>
								</div>
							);
						})}
						<AppSubTitle text={"Total de Asignaciones: " + listCodes.length + " unidades"} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AssignationsVerifyScreen;
