import React, { useRef, useState } from "react";
import apiPerson from "../../../apis/person/apiPerson";
import AppInput from "../../controls/AppInput";
import { useReactToPrint } from "react-to-print";
import NotificationActToPrint from "../../screens/forms/NotificationActToPrint";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import AppCalendar from "../../controls/AppCalendar";

function MeterWithdrawalNotification() {
	const componentRef = useRef();
	const [inscriptionCode, setInscriptionCode] = useState("");
	const [person, setPerson] = useState({});
	const [notificationDate, setNotificationDate] = useState(new Date());
	const [instalationDate, setInstalationDate] = useState(new Date());

	const onChangeInscriptionCode = (event) => {
		if (event.key === "Enter") {
			SearhPerson();
		}
	};

	const SearhPerson = async () => {
		var response = await apiPerson.getPersonByInscriptionCode(inscriptionCode);

		if (response.ok) {
			setPerson(response.data);
			SendToPrintForm();
		} else {
			setPerson({});
		}
	};

	const SendToPrintForm = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<div className="row p-5">
			<div className="col-10">
				<AppTitle text="Notificacion de Retiro: " />
				<AppBreadCrumb items={[{ label: "Formularios" }, { label: "Notificaciones de Retiro", active: true }]} />
			</div>

			<div className="col-md-12 card">
				<div className="card-body text-center">
					<div className="col-md-12 py-4 m-auto row">
						<div className="col-md-6 text-center">
							<AppCalendar label="Notificado:" value={notificationDate} onChange={setNotificationDate} />
						</div>
						<div className="col-md-6 text-center">
							<AppCalendar label="Instalado:" value={instalationDate} onChange={setInstalationDate} />
						</div>
					</div>
					<AppInput
						label="Codigo de Inscripcion del Usuario"
						onChange={({ target }) => setInscriptionCode(target.value)}
						onKeyPress={(event) => onChangeInscriptionCode(event)}
						placeholder="Codigo de Inscripcion"
					/>
					<button className="btn btn-primary" onClick={SendToPrintForm}>
						Enviar a Imprimir
					</button>
				</div>
			</div>
			<div className="col-md-12">
				<NotificationActToPrint
					person={person}
					ref={componentRef}
					instalationDate={instalationDate.toLocaleDateString()}
					notificationDate={notificationDate.toLocaleDateString()}
				/>
			</div>
		</div>
	);
}

export default MeterWithdrawalNotification;
