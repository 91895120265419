import React, { useEffect, useState } from "react";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import { Link, useHistory } from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import apiWorkUnit from "../../../apis/workUnit/apiWorkUnit";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";

function ListWorkUnitsScreen() {
	const headers = ["Fecha", "Unidad de Trabajo"];
	const values = ["created", "name"];
	const buttons = [
		{ onClick: (workUnit) => EditSelectedWorkUnit(workUnit), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (user) => ShowDeleteSelectedWorkUnit(user), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();
	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [workUnitList, setWorkUnitList] = useState([]);
	const [loading, setLoading] = useState(false);

	const GetWorkUnits = async (page = 1) => {
		setLoading(true);
		var response = await apiWorkUnit.getWorkUnitsPaginated(page, filter);
		setLoading(false);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setWorkUnitList(response.data.results);
		}
	};

	const ShowDeleteSelectedWorkUnit = (workUnit) => {
		AppAlert.OptionsAlert(
			"Esta unidad de trabajo se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteWorkUnit(workUnit.id);
			}
		});
	};

	const DeleteWorkUnit = async (workUnit_id) => {
		var response = await apiWorkUnit.deleteWorkUnit(workUnit_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetWorkUnits();
		}
	};

	const EditSelectedWorkUnit = (workUnit) => {
		history.push({ pathname: "/work-unit/edit", state: workUnit });
	};

	useEffect(() => {
		GetWorkUnits();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-10">
				<AppTitle text="Lista de Unidades de Trabajo Registrados:" />
				<BreadCrumb items={[{ label: "Unidad de Trabajo" }, { label: "Lista de Unidades de Trabajo Registrados", active: true }]} />
			</div>
			<div className="col-2 d-flex align-items-center">
				<Link className="btn btn-sm btn-success" to={"work-unit/create"}>
					Registrar Unidad de Trabajo
				</Link>
			</div>
			<div className="col-12">
				<TablePaginate
					buttons={buttons}
					headers={headers}
					rows={workUnitList}
					values={values}
					onSearchApi={(page) => GetWorkUnits(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ListWorkUnitsScreen;
