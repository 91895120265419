import apiBase from "../apiBase";

export default {
	// SALE
	createSale(sale) {
		const url = "api/sale/create";
		return apiBase.post(url, sale);
	},
	updateSale(sale) {
		const url = "api/sale/update";
		return apiBase.put(url, sale);
	},
	deleteSale(sale_id) {
		const url = "api/sale/delete/" + sale_id;
		return apiBase.delete(url);
	},
	getSalesPaginated(page = 1, filters = "") {
		if (page < 1) page = 1;
		const url = "api/sale/getSalesPaginated?page=" + page + "&&filters=" + filters;
		return apiBase.get(url);
	},
	getSalesDropdown(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/sale/getSalessDropdown?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
};
