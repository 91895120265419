import apiService from "../../apis/service/apiService";
import React, { useEffect, useState } from "react";
import AppAlert from "../common/AppAlert";
import apiPerson from "../../apis/person/apiPerson";
import AppSelect from "../controls/AppSelect";
import helperDate from "../../helpers/helperDate";
import AppClock from "../controls/AppClock";
import AppCalendar from "../controls/AppCalendar";
import AppLoading from "../common/AppLoading";

function AttendanceExtempCreateForm() {
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [person, setPerson] = useState({});
	const [service, setService] = useState({});
	const [personList, setPersonList] = useState([]);
	const [personListToSearch, setPersonListToSearch] = useState([]);
	const [serviceList, setServiceList] = useState([]);
	const [attendedDate, setAttendedDate] = useState(new Date());

	useEffect(() => {
		getServices();
		getAllPersons();
		return () => {};
	}, []);

	const getServices = async () => {
		var response = await apiService.getServicesDropdown();

		if (response.ok) {
			setServiceList(response.data);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const getAllPersons = async () => {
		var response = await apiPerson.getPersonsDropdown();
		if (response.ok) {
			setPersonList(response.data);
		} else {
			AppAlert.SimpleAlert(response.message, "error");
		}
	};

	const CreateAttendance = async () => {
		const attendance = {
			attended_date: helperDate.parseToServerFormat(attendedDate),
			service_id: service ? service.value : null,
			person_id: service ? person.value : null,
		};

		setLoading(true);
		const response = await apiService.createExtempAttendance(attendance);
		setLoading(false);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
		} else {
			setErrors(response);
		}
	};

	const onInputChange = (value = "") => {
		if (value && value.length > 2) {
			var persons = personList;
			const filteredPersons = persons.filter((x) => x.label.toUpperCase().includes(value.toUpperCase()));
			setPersonListToSearch(filteredPersons);
		} else {
			setPersonListToSearch([]);
		}
	};

	return (
		<div className="row">
			<div className="col-12 card">
				<div className="row card-body">
					<div className="col-12 form-group text-center">
						<b>HORA Y FECHA DE ATENCIÓN</b>
						<AppClock />
					</div>
					<div className="col-4 form-group">
						<AppSelect label="Datos del cliente" onInputChange={onInputChange} errors={errors.person_id} onChange={setPerson} items={personListToSearch} />
					</div>
					<div className="col-4 form-group">
						<AppSelect label="Servicio: " errors={errors.service_id} onChange={setService} items={serviceList} />
					</div>
					<div className="col-4 form-group">
						<AppCalendar label="Fecha de Registro: " onChange={setAttendedDate} value={attendedDate} />
					</div>
				</div>
				<div className="text-center">
					<small className="text-danger">
						El registro de asistencias extemporáneas no valida que la persona tenga una asistencia para el servicio y el día especificado.
					</small>
					<br />
					<button onClick={CreateAttendance} className="my-4 btn btn-sm btn-primary">
						Registrar Asistencia Extemporánea
					</button>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default AttendanceExtempCreateForm;
