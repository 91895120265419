import apiBase from "../apiBase";

export default {
	uploadPersonsSedacaj(data) {
		const url = "api/person/import/persons/sedacaj";
		return apiBase.post(url, data);
	},

	getObservationsDropdown() {
		const url = "api/person/observation/getObservationsDropdown";
		return apiBase.get(url);
	},

	upload(data) {
		const url = "api/person/upload_prove";
		return apiBase.post(url, data);
	},

	uploadPersons(data) {
		const url = "api/person/import/persons";
		return apiBase.post(url, data);
	},

	createPerson(person) {
		const url = "api/person/create";
		return apiBase.post(url, person);
	},

	createAssignment(assignment) {
		const url = "api/person/assignment/create";
		return apiBase.post(url, assignment);
	},

	createAssignmentDetail(assignment_detail) {
		const url = "api/person/assignmentDetail/create";
		return apiBase.post(url, assignment_detail);
	},

	instalateAssignmentDetail(assignment_detail) {
		const url = "api/person/assignmentDetail/installed";
		return apiBase.post(url, assignment_detail);
	},

	assignateAssignment(assignment) {
		const url = "api/person/assignment/assignate";
		return apiBase.post(url, assignment);
	},

	deletePerson(personId) {
		const url = "api/person/delete/" + personId;
		return apiBase.delete(url);
	},

	deleteUser(userId) {
		const url = "api/person/user/delete/" + userId;
		return apiBase.delete(url);
	},

	deleteAssignmentDetail(assignment_detail_id) {
		const url = "api/person/assignmentDetail/delete/" + assignment_detail_id;
		return apiBase.delete(url);
	},

	updatePerson(person) {
		const url = "api/person/update";
		return apiBase.put(url, person);
	},

	updateCodePerson(person) {
		const url = "api/person/updateCodePerson";
		return apiBase.put(url, person);
	},

	getPersonByDNI(dni) {
		const url = "api/person/searchPersonByDNI/?dni=" + dni;
		return apiBase.get(url);
	},

	getAssignmentById(assignment_id) {
		const url = "api/person/assignment?assignment_id=" + assignment_id;
		return apiBase.get(url);
	},

	getPersonByInscriptionCode(inscription_code) {
		const url = "api/person/searchPersonByInscriptionCode?inscription_code=" + inscription_code;
		return apiBase.get(url);
	},

	getPersonByNames(names) {
		const url = "api/person/filterPersonsByNames/?names=" + names;
		return apiBase.get(url);
	},

	getPersonsByCompany() {
		const url = "api/person/getPersonsByCompanyId";
		return apiBase.get(url);
	},

	getPersons() {
		const url = "api/person/getAllPersons";
		return apiBase.get(url);
	},

	getPersonsDropdown() {
		const url = "api/person/getPersonsDropdown";
		return apiBase.get(url);
	},

	getInChargedsDropdown() {
		const url = "api/person/incharged/getInchargedsDropdown";
		return apiBase.get(url);
	},

	getPersonsPaginated(page = 1, names = "") {
		const url = "api/person/getPersonsPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},

	getAssignmentsPaginated(page = 1, data) {
		const name = data.name;
		const encharged_id = data.encharged_id ? data.encharged_id : "";
		const inscription_code = data.inscription_code;
		const init_date = data.init_date ? data.init_date : null;
		const end_date = data.end_date ? data.end_date : null;
		const is_to_report = data.is_to_report;
		const assignment_state = data.assignment_state ? data.assignment_state : "";

		const url =
			"api/person/assignment/getAssignmentsPaginated?page=" +
			page +
			"&&name=" +
			name +
			"&&assignment_state=" +
			assignment_state +
			"&&encharged=" +
			encharged_id +
			"&&is_to_report=" +
			is_to_report +
			"&&inscription_code=" +
			inscription_code +
			"&&init_date=" +
			init_date +
			"&&end_date=" +
			end_date;
		return apiBase.get(url);
	},

	DownloadAssignmentsReport(data, filename) {
		const name = data.name;
		const encharged_id = data.encharged_id ? data.encharged_id : "";
		const inscription_code = data.inscription_code;
		const init_date = data.init_date ? data.init_date : null;
		const end_date = data.end_date ? data.end_date : null;
		const is_to_report = data.is_to_report;
		const assignment_state = data.assignment_state ? data.assignment_state : "";

		const url =
			"api/person/assignment/report/downloadAssignmentsReport?name=" +
			name +
			"&&assignment_state=" +
			assignment_state +
			"&&encharged=" +
			encharged_id +
			"&&is_to_report=" +
			is_to_report +
			"&&inscription_code=" +
			inscription_code +
			"&&init_date=" +
			init_date +
			"&&end_date=" +
			end_date;
		return apiBase.getAttachment(url, filename);
	},

	getPersonsPaginatedByNotificationDate(page = 1, notification_date = "") {
		const url = "api/person/getPersonsPaginatedByNotificationDate?page=" + page + "&&notification_date=" + notification_date;
		return apiBase.get(url);
	},

	getPersonsPaginatedByInstalationDate(page = 1, instalation_date = "") {
		const url = "api/person/getPersonsPaginatedByInstalationDate?page=" + page + "&&instalation_date=" + instalation_date;
		return apiBase.get(url);
	},

	changeNotificationDate(data) {
		const url = "api/person/changeNotificationDate";
		return apiBase.put(url, data);
	},

	observatePerson(data) {
		const url = "api/person/observatePerson";
		return apiBase.put(url, data);
	},

	changeInstalationDate(data) {
		const url = "api/person/changeInstalationDate";
		return apiBase.put(url, data);
	},

	getReportSedacaj(page = 1, data) {
		const initDate = data.initDate ? data.initDate : "";
		const endDate = data.endDate ? data.endDate : "";
		const contractor = data.contractor ? data.contractor : "";
		const notificator = data.notificator ? data.notificator : "";
		const gasfitter = data.gasfitter ? data.gasfitter : "";
		const inscription_code = data.inscription_code ? data.inscription_code : "";
		const person_level = data.person_level ? data.person_level : "";
		const block = data.block ? data.block : "";
		const last_names = data.last_names ? data.last_names : "";
		const address = data.address ? data.address : "";
		const newMeasurer = data.newMeasurer;
		const oldMeasurer = data.oldMeasurer;
		const states = data.states;
		const byStates = data.byStates;
		const observation = data.observation ? data.observation : "";

		const url =
			"api/person/sedacaj/report?page=" +
			page +
			"&&initDate=" +
			initDate +
			"&&endDate=" +
			endDate +
			"&&inscription_code=" +
			inscription_code +
			"&&contractor=" +
			contractor +
			"&&notificator=" +
			notificator +
			"&&gasfitter=" +
			gasfitter +
			"&&person_level=" +
			person_level +
			"&&block=" +
			block +
			"&&last_names=" +
			last_names +
			"&&address=" +
			address +
			"&&oldMeasurer=" +
			oldMeasurer +
			"&&newMeasurer=" +
			newMeasurer +
			"&&states=" +
			states +
			"&&byStates=" +
			byStates +
			"&&observation=" +
			observation;
		return apiBase.get(url);
	},

	downloadReportPersons(data, filename) {
		const initDate = data.initDate ? data.initDate : "";
		const endDate = data.endDate ? data.endDate : "";
		const contractor = data.contractor ? data.contractor : "";
		const notificator = data.notificator ? data.notificator : "";
		const gasfitter = data.gasfitter ? data.gasfitter : "";
		const inscription_code = data.inscription_code ? data.inscription_code : "";
		const person_level = data.person_level ? data.person_level : "";
		const block = data.block ? data.block : "";
		const last_names = data.last_names ? data.last_names : "";
		const newMeasurer = data.newMeasurer;
		const address = data.address ? data.address : "";
		const oldMeasurer = data.oldMeasurer;
		const states = data.states;
		const byStates = data.byStates;
		const observation = data.observation ? data.observation : "";

		const url =
			"api/person/sedacaj/report/download?initDate=" +
			initDate +
			"&&endDate=" +
			endDate +
			"&&inscription_code=" +
			inscription_code +
			"&&contractor=" +
			contractor +
			"&&notificator=" +
			notificator +
			"&&address=" +
			address +
			"&&gasfitter=" +
			gasfitter +
			"&&person_level=" +
			person_level +
			"&&block=" +
			block +
			"&&last_names=" +
			last_names +
			"&&oldMeasurer=" +
			oldMeasurer +
			"&&newMeasurer=" +
			newMeasurer +
			"&&states=" +
			states +
			"&&byStates=" +
			byStates +
			"&&observation=" +
			observation;

		return apiBase.getAttachment(url, filename);
	},

	downloadReportSedacaj(data, filename) {
		const initDate = data.initDate ? data.initDate : "";
		const endDate = data.endDate ? data.endDate : "";
		const contractor = data.contractor ? data.contractor : "";
		const notificator = data.notificator ? data.notificator : "";
		const gasfitter = data.gasfitter ? data.gasfitter : "";
		const inscription_code = data.inscription_code ? data.inscription_code : "";
		const person_level = data.person_level ? data.person_level : "";
		const block = data.block ? data.block : "";
		const last_names = data.last_names ? data.last_names : "";
		const newMeasurer = data.newMeasurer;
		const oldMeasurer = data.oldMeasurer;
		const address = data.address ? data.address : "";
		const states = data.states;
		const byStates = data.byStates;
		const observation = data.observation ? data.observation : "";

		const url =
			"api/person/sedacaj/report/downloadToSedacaj?initDate=" +
			initDate +
			"&&endDate=" +
			endDate +
			"&&inscription_code=" +
			inscription_code +
			"&&contractor=" +
			contractor +
			"&&notificator=" +
			notificator +
			"&&gasfitter=" +
			gasfitter +
			"&&address=" +
			address +
			"&&person_level=" +
			person_level +
			"&&block=" +
			block +
			"&&last_names=" +
			last_names +
			"&&oldMeasurer=" +
			oldMeasurer +
			"&&newMeasurer=" +
			newMeasurer +
			"&&states=" +
			states +
			"&&byStates=" +
			byStates +
			"&&observation=" +
			observation;

		return apiBase.getAttachment(url, filename);
	},
};
